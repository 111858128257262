<template>
  <div class="send-phone">
    <modal-header-with-previous
      class="modal-header-with-previous"
      current-modal-name="send-with-text-message-modal"
      previous-modal-name="invite-patient-modal"
    >
      <span slot="header-title" class="modal-header-with-previous__title">
        {{ $t('dashboard.label.sendTextMessage') }}
      </span>
    </modal-header-with-previous>
    <main class="send-phone__message-template">
      <message-template
        type="SMS"
        @send-template="sendTextMessageTemplate"
        @get-template="updateSmsCounter"
      >
        <template slot="header-content">
          <div v-if="smsLimitMessage" class="send-phone__sms-warning flex mb15">
            <span class="sms-warning__icon fas fa-exclamation-triangle mr10" />
            <span class="sms-warning__text label-text">
              <i18n path="dashboard.label.reachLimitWarning" tag="span">
                <span>{{ customSmsCounter }}</span>
                <span>{{ customSmsLimit }}</span>
                <span class="sms-warning__email mail-text">info@universkin.com</span>
              </i18n>
            </span>
          </div>
          <div class="send-phone__phone-list phone-list">
            <div class="send-phone__phone-list__title flex--align-center label-text">
              {{ $t('dashboard.label.to') }}
              <div
                v-tooltip="{
                  content: $t('dashboard.label.addMultiplePhones'),
                  placement: 'right-start'
                }"
                class="icon-info custom-icon"
              ></div>
            </div>
            <phone-selector
              :phones="phones"
              :valid="isPhoneListValid"
              :validation-error-message="$t('dashboard.warning.requiredField')"
              @add-phone="addPhone"
              @delete-phone="deletePhone"
            />
          </div>

          <dashboard-constraint
            v-model="patientSmsAgreement"
            name="patientSmsAgreement"
            class="phone-list__constraint"
            :constraint-text="$t('dashboard.consent.sendSms.agreeToMarketingOffers')"
            :hint-text="$t('dashboard.consent.sendSmsHint.agreeToMarketingOffers')"
            required
          />

          <div class="divider mb14 otherXS"></div>
        </template>
        <div slot="send-button" slot-scope="{ sendTemplate }">
          <button
            class="send-phone-button rounded-button-orange"
            :disabled="isSmsLimitReached"
            @click="sendTemplate"
          >
            {{ $t('dashboard.action.send') }}
          </button>
        </div>
      </message-template>
    </main>

    <invite-template-sent-modal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { isEmpty } from 'lodash';

import ModalHeaderWithPrevious from '@/modules/dashboard/components/dashboard/common/ModalHeaderWithPrevious';
import MessageTemplate from '@/modules/dashboard/components/dashboard/common/MessageTemplate';
import PhoneSelector from '@/modules/dashboard/components/dashboard/common/PhoneSelector';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import DashboardConstraint from '@/modules/dashboard/components/dashboard/common/DashboardConstraint';

import { sendMarketingTemplateInvites } from '@/modules/dashboard/api';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import rootTypes from '@/store/types';
import { MESSAGE_TEMPLATE_TYPES } from '@/modules/dashboard/api/constants';

const SMS_COUNT_LIMIT = 5;

export default {
  name: 'SendWithTextMessage',
  components: {
    DashboardConstraint,
    InviteTemplateSentModal,
    PhoneSelector,
    MessageTemplate,
    ModalHeaderWithPrevious
  },
  data() {
    return {
      customSmsCounter: 0,
      customSmsLimit: 125,
      phones: [],
      isPhoneListValid: true,
      patientSmsAgreement: false
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    }),
    smsCounterDifference() {
      return this.customSmsLimit - this.customSmsCounter;
    },
    smsLimitMessage() {
      return this.smsCounterDifference <= SMS_COUNT_LIMIT;
    },
    isSmsLimitReached() {
      return this.smsCounterDifference <= 0;
    }
  },
  methods: {
    updateSmsCounter({ customSmsCounter, customSmsLimit }) {
      this.customSmsCounter = customSmsCounter;
      this.customSmsLimit = customSmsLimit;
    },
    addPhone(phone) {
      this.phones.push(phone);
      this.isPhoneListValid = true;
    },
    deletePhone(phoneIndex) {
      this.phones.splice(phoneIndex, 1);
    },
    async sendTextMessageTemplate(messageTemplateDto) {
      const isValidated = await this.$validator.validateAll();

      if (isEmpty(this.phones)) {
        this.isPhoneListValid = false;

        return;
      }

      this.isPhoneListValid = true;

      if (!isValidated) {
        return;
      }

      const response = await sendMarketingTemplateInvites(this.userId, {
        messageTemplateDto,
        destinations: this.phones,
        wayOfSend: MESSAGE_TEMPLATE_TYPES.SMS
      });

      if (!hasResponseErrors(response)) {
        this.$modal.hide('send-with-text-message-modal');
        this.$modal.show('invite-template-sent-modal');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../../assets/scss/common/buttons';
@import '../../../../../../assets/scss/common/text';
@import '../../../../../../assets/css/common/icons.css';
@import '../../../../../../assets/scss/helpers/colors';
@import '../../../../../../assets/scss/modals/modal-header-with-previous-shared';
@import '../../../../../../assets/scss/invite-patients/invite-patient-shared';

.send-phone {
  font-size: 15px;
  line-height: 17px;
  color: $main-title-color;

  &-button {
    height: 36px;
  }

  &__sms-warning {
    width: 100%;

    padding: 15px;

    background: $beige;
    border-radius: 8px;
  }

  &__phone-list {
    margin-bottom: 20px;

    &__title {
      margin-bottom: 15px;
    }
  }
}

.phone-list {
  &__constraint {
    margin-bottom: 20px;
  }
}

.sms-warning {
  &__icon {
    color: $pale-orange-color;
  }

  &__email {
    font-weight: bold;
    color: $blue-steel-color;
  }
}

@media (max-width: 767px) {
  .send-phone {
    font-size: 13px;
    line-height: 15px;
    background-color: white;
    min-height: 100vh;
  }

  .phone-list {
    &__constraint {
      margin-bottom: 10px;
    }
  }

  .send-phone-button {
    height: 32px;
    font-size: 15px;
  }
}
</style>
