<template>
  <div class="phone-selector__container">
    <div
      class="phone-selector rounded-input input-focus-within flex--align-center"
      :class="{ 'error-highlight': isValidationError }"
    >
      <div class="phone-selector__label flex--align-center">
        <div class="icon-phone-right custom-icon mr10"></div>
        <div
          v-for="(phone, index) in phones"
          :key="phone"
          class="phone-selector__phone flex--align-center"
        >
          <span class="phone-selector__phone__text ellipsized">{{ phone }}</span>
          <div class="icon-close pointer ml10" @click="deletePhone(index)"></div>
        </div>
        <phone-edit-field
          :phone="newPhone"
          :iso-code="newIsoCode"
          :class="{ none: isMaxNumberOfPhones }"
          :placeholder="inputPlaceholder"
          transparent
          :should-highlight-on-focus="false"
          @update-phone-number="addPhone"
          @update-iso-code="updateIsoCode"
        />
      </div>
    </div>
    <field-error v-if="isValidationError" :error-message="validationErrorMessage"></field-error>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { countries } from 'countries-list';
import rootTypes from '@/store/types';

import FieldError from '@/modules/dashboard/components/common/FieldError';
import PhoneEditField from '@/modules/dashboard/components/profile/PhoneEditField';

export default {
  name: 'PhoneSelector',
  components: { PhoneEditField, FieldError },
  props: {
    phones: {
      type: Array,
      default: () => []
    },
    maxNumberOfPhones: {
      type: Number,
      default: 999
    },
    valid: {
      type: Boolean,
      default: true
    },
    validationErrorMessage: {
      type: String,
      default: 'The field is not valid'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newPhone: '',
      newIsoCode: ''
    };
  },
  computed: {
    ...mapGetters({
      user: rootTypes.getters.GET_USER
    }),
    isValidationError() {
      return !this.valid;
    },
    isMaxNumberOfPhones() {
      return this.phones.length >= this.maxNumberOfPhones;
    },
    inputPlaceholder() {
      return this.placeholder || this.$t('dashboard.label.addPhoneNumber');
    }
  },
  created() {
    this.newIsoCode = this.user.officePhoneIso;
  },
  methods: {
    addPhone(newPhone) {
      this.newPhone = newPhone;

      if (this.newPhone) {
        const { phone } = countries[this.newIsoCode.toUpperCase()];

        this.$emit('add-phone', `+${phone}${this.newPhone}`);
        this.$nextTick(() => {
          this.newPhone = '';
        });
      }
    },
    updateIsoCode(isoCode) {
      this.newIsoCode = isoCode;
    },
    deletePhone(phoneIndex) {
      this.$emit('delete-phone', phoneIndex);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/inputs.css';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/errors.css';
@import '../../../../../assets/css/common/icons.css';

.phone-selector {
  flex-wrap: wrap;
  padding: 0 12px;
  width: 100%;
  min-height: 36px;

  &__phone {
    border: 1px solid $dim-white;
    border-radius: 15px;
    margin: 6px 10px 6px 0;
    padding: 5px 8px 5px 10px;

    &__text {
      max-width: 200px;
      font-size: 13px;
      line-height: 15px;
      font-weight: 300;
    }
  }

  &__label {
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .phone-selector {
    &__phone {
      &__text {
        max-width: 170px;
      }
    }
  }
}
</style>
